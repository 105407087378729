export const useModalStore = defineStore('modal', () => {
  const modalType = ref('')
  const modalActive = ref(false)
  const modalData = ref({})
  let modalOnClose: (() => void) | null = null

  function openModal(type: string, data: any, onClose = null) {
    modalData.value = data
    modalType.value = type
    modalActive.value = true
    modalOnClose = onClose
  }

  function closeModal() {
    modalActive.value = false
    modalData.value = {}
    modalType.value = ''

    if (modalOnClose && typeof modalOnClose === 'function') {
      modalOnClose()
    }
  }

  return {
    modalType,
    modalActive,
    modalData,
    openModal,
    closeModal,
  }
})
